<template>
  <div class="innerbox">
    <econ-where  v-if="!nodata" 
      :rangeYear="rangeYear"
      :curAreaId="curAreaId"
      :citylist="citylist"
      @cid-rangeyear="cidRangeyear"
      @change-areaid="changeAreaid"
    ></econ-where>
    <div class="scrollbox">
      <div v-if="nodata">
        <el-result
          icon="info"
          title="暂无数据"
          subTitle="当前城市数据不存在..."
        >
        </el-result>
      </div>
      <div
        v-else
        class="report"
      >
        <div class="tablebox">
          <div class="title"> {{tableData.title}} <span style="font-size:12px;" v-if="tableData.unit" >({{ tableData.unit}})</span></div>
          <div class="downbox">
            <el-button
              type="primary"
              icon="el-icon-download"
              size="mini"
              @click.prevent="downloadExcel"
            >导出Excel</el-button>
          </div>
          <el-table
            ref="singleTable"
            @row-click="clickRow"
            class="table"
            :data="tableData.table"
            size="mini"
            :highlight-current-row="true"
           
          >
            <el-table-column
              v-for="cell in tableData.header"
              :key="cell.prop"
              :prop="cell.prop"
              :label="cell.label"
              :class-name="cell.prop"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="footerinfo">
            <time-source :data="{time:tableData.uptime,source:tableData.source}"></time-source>
          </div>
        </div>
        <div class="addtable">
          <table-2
            :tableData="addTableData"
            :cateid="cateid[1]"
            @download="downloadExcelByCateid"
             :hideRight="true"
          ></table-2>
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EconWhere from "../../components/cityreserch/EconWhere.vue";
import Table2 from "@/components/tables/Table2.vue";
import TimeSource from "../../components/cityreserch/TimeSource.vue";
import {
  cityYearByCateid,
  dataByCateid,
  RangeYearDownloadExcelByCateid,
  rangeYearByCateid,
  addTableDataByCateid,
  downloadExcelByCateid   
} from "../../api/econ";
export default {
  name: "VueElementTupaiGdp",
  components: { EconWhere, TimeSource, Table2 },
  data() {
    return {
      cateid: [21, 2101],
      addTableData: {}, //附加表数据  table1 组建用的props
      nodata: false,
      rangeYear: [],
      curAreaId: 0,
      citylist: [],
      tableData: {},
      valueObj: null,
        download_start_year:null,
     download_end_year:null
    };
  },

  mounted() {
    this.getWhere();
  },
  computed: {
    shi: {
      get() {
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
  },
  watch: {
    shi() {
      //   this.currentArea = this.cityList[0].id
      this.getWhere();
    },
  },
  methods: {
    //更改当前的区id
    changeAreaid(id) {
      console.log(this.rangeYear);
      this.getCurrentRange({ city_plate_id: id,cateid:this.cateid[0]});
    },

    getCurrentRange(params) {
      rangeYearByCateid(params).then((res) => {
       
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.rangeYear = res.data.rangeYear;
      });
    },

   

    //处理table1  emit的方法   根据cateid下载excel（ 不选择年份区间通用）
    downloadExcelByCateid(obj) {
      const params = {
        city_plate_id:this.curAreaId,
        cateid: obj.cateid,  //分类Id
        year: obj.year  //年份
      };

      downloadExcelByCateid(params).then((res) => {
        if (res.code === 1001) {
          this.$message("此文档不存在...");
        } else {
          this.download = res.data;
          window.location.href = this.download.fileUrl;

          // this.filesToRar([this.download],this.download.renameFileName)
        }
      });
    },
    //下载excel
    downloadExcel() {


    const params = {
        city_plate_id: this.curAreaId,
        start_year: this.download_start_year || this.rangeYear[0],
        end_year: this.download_end_year||this.rangeYear[1],
        cateid:this.cateid[0]
    };


  

    downloadExcelByCateid(params).then((res) => {
        if (res.code === 1001) {
        this.$message("此文档不存在...");
        } else {
        this.download = res.data;
        window.location.href = this.download.fileUrl;
        }
    });
    },

    //子组建返回的事件
    cidRangeyear(obj) {
    this.curAreaId = obj.areaId;
    const params = {
        city_plate_id: obj.areaId,
        start_year: obj.startyear,
        end_year: obj.endyear,
        cateid:this.cateid[0]
    };
    this.download_start_year = obj.startyear
    this.download_end_year = obj.endyear

    this.getData(params);
    },
    //设置当前选中行
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    //点击行事件
    clickRow(row, column, event) {
      this.valueObj = row;
      this.getAddTableData();

      document.getElementsByClassName('addtable')[0].scrollIntoView({behavior: "smooth"})
    },

    getWhere() {
        //参数 为通用的参数，当前城市id 和当前分类id
      const params = { city_plate_id: this.$store.state.shi,cateid:this.cateid[0]};

      cityYearByCateid(params).then((res) => {
       
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }else{
            this.nodata = false;
        }
        this.citylist = res.data.citylist;
        this.rangeYear = res.data.rangeYear;
        res.data.citylist.every((item) => {
          if (!item.disabled) {
            this.curAreaId = item.id;
            return false;
          }
        });

 


         
        if (this.rangeYear.length !== 0) {
          const params = {
            city_plate_id: this.curAreaId,
            start_year: this.rangeYear[0],
            end_year: this.rangeYear[1],
            cateid: this.cateid[0],
          };
          this.nodata = false
          this.getData(params);
        } else {
          this.tableData = []
          this.nodata = true
          this.$message('暂无数据')
        }





      });
    },

    getData(params) {
      dataByCateid(params).then((res) => {
        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.tableData = res.data; //存储返回的数据
        this.valueObj = null;
        

        this.setCurrent(res.data.table[0]); //设置选中第一条

        this.getAddTableData(); //获取table1的数据
      });
    },

    //获取table1的数据
    //根据返回的第一条数据，生成传入table1 组建的条件 （生成年份条件）
    getAddTableData() {
      let valueObj = this.valueObj || this.tableData.table[0]; //获取点击的当前行的值 或者 是初始化数据的值
      console.log("获取当初始化以及点击当前行的数据", valueObj);
      //获取并处理组建需要的数据
      const params = { city_plate_id:this.curAreaId,year: valueObj.cell1,cateid:this.cateid[1] };
      addTableDataByCateid(params).then((res) => {
        const addData = res.data;
        this.addTableData = addData;
      });
    
      


    },

    
  },
};
</script>

<style lang="scss" scoped>
.innerbox {
  width: 100%;
  height: auto;
}
.scrollbox {
  height: calc(100vh - 260px);
  overflow: scroll;
  width: 96%;
  margin: 0 auto;
}

.report {
  //  padding-top:140px;
  //  margin-top:140px;

  //  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .tablebox {
    width: 100%;
    .title {
        margin-top:15px;
      font-size: 18px;
      font-weight: 400;
    }
    .downbox {
      text-align: right;
      position: relative;
      top: -20px;
      left: -40px;
    }
  }
  .piebox {
    width: 40%;
    display: flex;
    flex-direction: column;

    .pie {
      min-height: 260px;
    }
  }
}
::v-deep .cell3,
::v-deep .cell5,
::v-deep .cell9 {
//   border-right: solid 2px #efefef;
}
.addtable {
}
</style>