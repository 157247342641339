<template>
  <div class="content">
    <div class="downbox">
      <el-button
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click.prevent="downloadExcel"
      >导出Excel</el-button>
    </div>
    <div :class="{'tablebox':true,'hideRight':hideRight }">

      <div class="left">

        <div class="title"> {{tableData.title}}
          <span class="uint">{{tableData.unit}}</span>
        </div>
        <el-table
          class="table"
          :data="tableData.table"
          size="mini"
          row-key="id"
          :default-expand-all="true"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          <el-table-column
            v-for="cell in tableData.header"
            :key="cell.prop"
            :prop="cell.prop"
            :label="cell.label"
            :class-name="cell.prop"
            align="left"
          >
          </el-table-column>
        </el-table>
        <div class="footerinfo">

          <time-source :data="{time:tableData.uptime,source:tableData.source}"></time-source>
        </div>
      </div>

      <div class="right">
        <div class="pie">
          <pie-2 :data="pieData"></pie-2>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import TimeSource from "@/components/cityreserch/TimeSource.vue";
import Pie2 from "@/components/echarts/pie2";

export default {
  name: "Table1",
  props: {
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    cateid: Number,
    pieChildren: {
      //显示children
      type: Number,
      default: 0,
    },
    hideRight: false,
  },
  components: { TimeSource, Pie2 },
  data() {
    return {
      pieData: {
        title: "",
        main: [],
      },
    };
  },
  watch: {
    tableData: {
      handler(nv) {
        if (nv != null) {
          this.handlePieData();
        }
      },
      deep: true,
    },
  },
  methods: {
    //下载excel
    downloadExcel() {
      console.log(
        '====ßtableData',
        this.tableData
      )
      const params = { cateid: this.cateid, year: this.tableData.year };
      this.$emit("download", params);
    },
    //处理pie数据
    handlePieData() {
      let pieData = {};
      let handleData = this.tableData.table;

      if (this.tableData != null && handleData != null) {
        if (this.pieChildren !== 0) {
         
          handleData = this.tableData.table[this.pieChildren - 1].children;
        }

        let data = handleData.map((item) => {
          const cur = {
            name: item.cell1,
            value: item.cell3,
          };
          return cur;
        });

        pieData.main = data;
        pieData.title = this.tableData.title;
        this.pieData = pieData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  border-top: solid 3px #efefef;
  margin-top: 10px;
}
.downbox {
  text-align: right;
  position: relative;
  top: 20px;
  left: -40px;
}
.tablebox {
  margin-top:50px;
  display: flex;
  flex: 1;
  justify-content: space-around;
  .left {
    width: 50%;
    .title {
      font-size: 16px;
    }
  }
  .right {
   
    width: 50%;
    .pie {
      width: 100%;
      height: auto;
    }
  }

  .uint {
    font-size: 12px;
  }

  ::v-deep .cell3,
  ::v-deep .cell2 {
    border-right: none;
  }
  ::v-deep .cell1,
  ::v-deep .cell2,
  ::v-deep .cell3 {
    // padding-left: 30px;
  }
}

.hideRight {
  display: flex;
  flex: 1;
  justify-content: space-around;
  .left {
    width: 96%;
    .title {
      font-size: 16px;
    }
  }
}
</style>